import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useTranslation } from 'react-i18next';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useCookies } from 'react-cookie';
import { url } from '../../../utils/url';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { useApi } from '../../../hooks/useApi';
import { GetMe } from '../../../api/AuthApi';
import { GetMyTransaction } from '../../../api/OrderApi';
import { sub } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setTransactionsStore } from '../../../store/transaction.store';
import { useResponsive } from '../../../hooks/useResponsive';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
export const Header = ({ check = {} }) => {
  const [cookie, setCookie, removeCookie] = useCookies(['jwt', 'lang']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMobileProfile, setAnchorElMobileProfile] = useState(null);
  const { t, i18n } = useTranslation();
  const GetMyTransactionApi = useApi(GetMyTransaction);
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const [currentLanguate, setCurrentLanguage] = useState(
    cookie.lang || i18n.language,
  );
  const [anchorElSaldo, setAnchorElSaldo] = useState(null);

  const open = Boolean(anchorEl);
  const openMobileProfile = Boolean(anchorElMobileProfile);
  const openSaldo = Boolean(anchorElSaldo);

  const navigate = useNavigate();
  const GetMeApi = useApi(GetMe);
  const [user, setUser] = useState({});
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    i18n.changeLanguage(cookie.lang || 'en');
  }, [cookie.lang]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (cookie?.jwt) {
      GetMeApi.sendRequest({ token: cookie.jwt }).then((result) =>
        setUser(result),
      );
      GetMyTransactionApi.sendRequest({ token: cookie.jwt }).then((result) => {
        setTransactions(result);
        dispatch(setTransactionsStore(result));
      });
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickMobileProfile = (event) => {
    setAnchorElMobileProfile(event.currentTarget);
  };
  const handleChangeLanguage = (lang) => {
    setAnchorEl(null);
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
    setCookie('lang', lang, { path: '/' });
    setTimeout(() => {
      navigate(0);
    }, 500);
  };

  const location = useLocation();

  const handleClickToSaldo = (event) => {
    setAnchorElSaldo(event.currentTarget);
  };
  const handleCloseToSaldo = () => {
    setAnchorElSaldo(null);
  };

  return (
    <AppBar position="fixed" color="inherit" variant="outlined">
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Toolbar>
              <Box sx={{ flexGrow: 1 }}>
                <Link to={url.orders}>
                  <img
                    src="/logo-en.jpeg"
                    alt="Auqphor"
                    style={{ width: '135px' }}
                  />
                </Link>
              </Box>

              {cookie.jwt && (
                <>
                  <Box
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                    className={styles.Header__Menu}
                  >
                    <Link
                      to={url.orders}
                      className={
                        location.pathname.indexOf('/orders') > -1
                          ? styles.Header__MenuItem
                          : ''
                      }
                    >
                      {t('Orders')}
                    </Link>
                    <Badge
                      color={
                        check?.noPayment?.length
                          ? 'error'
                          : check?.nearPayment?.length
                          ? 'warning'
                          : 'default'
                      }
                      variant="dot"
                    >
                      <Link
                        to={url.invoices}
                        className={
                          location.pathname.indexOf('/invoices') > -1
                            ? styles.Header__MenuItem
                            : ''
                        }
                      >
                        {t('Invoices')}
                      </Link>
                    </Badge>
                  </Box>
                  <Box sx={{ mr: isMobile ? 1 : 2 }}>
                    {isMobile ? (
                      <IconButton
                        aria-controls={openSaldo ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSaldo ? 'true' : undefined}
                        color="primary"
                        onClick={handleClickToSaldo}
                      >
                        <SavingsOutlinedIcon />
                      </IconButton>
                    ) : (
                      <Button
                        id="basic-button"
                        aria-controls={openSaldo ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSaldo ? 'true' : undefined}
                        onClick={handleClickToSaldo}
                        startIcon={<SavingsOutlinedIcon />}
                      >
                        {t('Balance')}
                      </Button>
                    )}

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSaldo}
                      open={openSaldo}
                      onClose={handleCloseToSaldo}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Stack sx={{ width: '250px', p: 1 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {t('Balance')}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography>{t('CurrentBalance')}</Typography>
                        {transactions?.ExchAdjustment?.map((t) => (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                            // sx={{ mb: 2 }}
                          >
                            <Typography>{t.CurrencyCode}</Typography>{' '}
                            <Typography
                              sx={{ color: +t.Saldo < 0 ? 'red' : 'green' }}
                            >
                              {t.Saldo.toFixed(2)}
                            </Typography>
                          </Stack>
                        ))}
                        <Typography sx={{ mt: 1 }}>{t('Invoices')}</Typography>
                        {transactions?.Sales?.map((t) => (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography>{t.CurrencyCode}</Typography>{' '}
                            <Typography
                              sx={{ color: +t.Saldo < 0 ? 'red' : 'green' }}
                            >
                              {t.Saldo.toFixed(2)}
                            </Typography>
                          </Stack>
                        ))}

                        <Typography sx={{ mt: 1 }}>{t('Payments')}</Typography>
                        {transactions?.Payment?.map((t) => (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography>{t.CurrencyCode}</Typography>{' '}
                            <Typography
                              sx={{ color: +t.Saldo < 0 ? 'red' : 'green' }}
                            >
                              {t.Saldo.toFixed(2)}
                            </Typography>
                          </Stack>
                        ))}

                        <Typography sx={{ mt: 1 }}>{t('Other')}</Typography>
                        {transactions?.NotSalesAndNotPayment?.map((t) => (
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography>{t.CurrencyCode}</Typography>{' '}
                            <Typography
                              sx={{ color: +t.Saldo < 0 ? 'red' : 'green' }}
                            >
                              {t.Saldo.toFixed(2)}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Menu>
                  </Box>
                  {(isTablet || isDesktop) && (
                    <Box sx={{ mr: 2 }}>
                      <Typography>{user?.OrganizationName}</Typography>
                    </Box>
                  )}
                </>
              )}
              <Button
                aria-label="language"
                onClick={handleClick}
                startIcon={<LanguageOutlinedIcon />}
              >
                {currentLanguate}
              </Button>

              {isDesktop
                ? cookie.jwt && (
                    <IconButton
                      onClick={() => {
                        removeCookie('jwt', {
                          expires: sub(new Date(), {
                            days: 1800,
                          }),
                          path: '/',
                        });

                        navigate(url.login);
                      }}
                    >
                      <LogoutOutlinedIcon />
                    </IconButton>
                  )
                : cookie.jwt && (
                    <>
                      <IconButton onClick={handleClickMobileProfile}>
                        <MenuOutlinedIcon />
                      </IconButton>
                      <Menu
                        id="language-menu"
                        anchorEl={anchorElMobileProfile}
                        open={openMobileProfile}
                        onClose={() => setAnchorElMobileProfile(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <Typography sx={{ px: 2, pb: 1 }}>
                          {user?.OrganizationName}
                        </Typography>
                        <Divider />
                        <MenuItem>
                          <Link
                            style={{
                              color: '#000',
                              textDecoration: 'none',
                              padding: '0px 0px',
                              paddingBottom: '5px',
                            }}
                            to={url.orders}
                            className={
                              location.pathname.indexOf('/orders') > -1
                                ? styles.Header__MenuItem
                                : ''
                            }
                          >
                            {t('Orders')}
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Badge
                            color={
                              check?.noPayment?.length
                                ? 'error'
                                : check?.nearPayment?.length
                                ? 'warning'
                                : 'default'
                            }
                            variant="dot"
                          >
                            <Link
                              to={url.invoices}
                              style={{
                                color: '#000',
                                textDecoration: 'none',
                                padding: '0px 0px',
                                paddingBottom: '5px',
                              }}
                              className={
                                location.pathname.indexOf('/invoices') > -1
                                  ? styles.Header__MenuItem
                                  : ''
                              }
                            >
                              {t('Invoices')}
                            </Link>
                          </Badge>
                        </MenuItem>
                        <Divider />

                        <MenuItem
                          onClick={() => {
                            removeCookie('jwt', {
                              expires: sub(new Date(), {
                                days: 1800,
                              }),
                              path: '/',
                            });

                            navigate(url.login);
                          }}
                        >
                          <LogoutOutlinedIcon sx={{ mr: 1 }} /> {t('logout')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
            </Toolbar>
          </Grid>
        </Grid>
      </Container>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleChangeLanguage('ru');
          }}
        >
          Русский язык
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLanguage('en');
          }}
        >
          English language
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLanguage('pl');
          }}
        >
          Polski
        </MenuItem>
      </Menu>
    </AppBar>
  );
};
