export const BACKEND_URL =
  process.env.REACT_APP_NODE_ENV == 'development'
    ? 'http://localhost:3001'
    : process.env.REACT_APP_CI == 'uat'
    ? 'https://api-uat.customerportal.aquaphor.com'
    : 'https://api.customerportal.aquaphor.com';

export const url = {
  home: '/',
  login: '/login',
  orders: '/orders/full',
  ordersDraft: '/orders/draft',
  singleOrders: '/orders/full/:SalesOrderNumber',
  draftOrder: '/orders/draft/:draftId',
  invoices: '/invoices',
  techMode: '/tech',
  error500: '/500',
  error404: '/404',
  registration: '/registration',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:forgotPasswordId',
};
