import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Default/Layout/MainContainer';
import {
  Box,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { useParams, useSearchParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import {
  // GetInvoiceOrders,
  GetSingleInvoice,
  GetSingleInvoiceLines,
} from '../api/OrderApi';
import { differenceInCalendarDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useResponsive } from '../hooks/useResponsive';
import { useSelector } from 'react-redux';
import transactionStore from '../store/transaction.store';

export const SingleInvoicePage = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const [invoice, setInvocie] = useState({});
  const [invocieOrders, setInvocieOrders] = useState([]);
  const [lines, setLines] = useState([]);
  const GetSingleInvoiceApi = useApi(GetSingleInvoice);
  // const GetInvoiceOrdersApi = useApi(GetInvoiceOrders);
  const GetSingleInvoiceLinesApi = useApi(GetSingleInvoiceLines);
  const [cookie] = useCookies('jwt');

  const [searchParams, setSearchParams] = useSearchParams();
  const InvoiceId = searchParams.get('InvoiceId');
  const transactionsStore = useSelector((state) => state.transactionsStore);
  const [toPayment, setToPayment] = useState(0);

  useEffect(() => {
    setToPayment(
      transactionsStore?.All?.find(
        (t) =>
          t.TransDate == invoice?.InvoiceDate &&
          t.Voucher == invoice?.LedgerVoucher,
      ),
    );
  }, [transactionsStore]);
  const GetSingleInvoiceHandler = async () => {
    const result = await GetSingleInvoiceApi.sendRequest({
      token: cookie.jwt,
      InvoiceId,
    });
    setInvocie(result);
  };

  const GetInvoiceLinesHandler = async () => {
    const result = await GetSingleInvoiceLinesApi.sendRequest({
      token: cookie.jwt,
      InvoiceId,
      InvoiceDate: invoice?.InvoiceDate,
    });
    setLines(result);
  };
  const [tab, setTab] = React.useState('1');

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (cookie.jwt) {
      GetSingleInvoiceHandler().then((result) => {
        GetInvoiceLinesHandler();
        // GetSingleInvoiceOrdersHandler();
      });
    }
  }, []);

  return (
    <MainContainer needAuth={true}>
      {isDesktop && (
        <Box
          sx={{
            width: 'calc(50% + 8px)',
            height: '100vh',
            position: 'fixed',
            top: 0,
            right: 0,
            background: '#fff',
            zIndex: -1,
            borderLeft: 'solid 1px #0000001f',
          }}
        ></Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Box sx={{ width: '100%' }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: '100%' }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  {invoice?.InvoiceId ? (
                    <Typography
                      as="h1"
                      variant="h5"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {invoice?.InvoiceId}
                    </Typography>
                  ) : (
                    <Skeleton variant="rounded" width={158} height={32} />
                  )}

                  {invoice?.InvoiceId ? (
                    <Typography variant="p" sx={{ fontWeight: 'bold', pr: 2 }}>
                      {t('from')}{' '}
                      {invoice?.InvoiceDate &&
                        format(invoice?.InvoiceDate, 'dd.MM.yyyy')}
                    </Typography>
                  ) : (
                    <Skeleton variant="rounded" width={105} height={19} />
                  )}
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Divider />
          <Typography as="h1" variant="h6" sx={{ fontWeight: 'normal', my: 2 }}>
            {t('OrderTitle')}
          </Typography>

          <>
            {invoice?.InvoiceId ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('Quantity')}</Box>

                <Box sx={{ pr: 2 }}>{invoice?.Qty}</Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {invoice?.InvoiceId ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('DueDate')}</Box>
                <Box sx={{ pr: 2 }}>
                  {invoice?.DueDate &&
                  invoice?.DueDate == '1900-01-01T12:00:00Z'
                    ? ''
                    : format(invoice?.DueDate, 'dd.MM.yyyy')}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}

            {invoice?.InvoiceId ? (
              toPayment && (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
                >
                  <Box>{t('ForPayment')}</Box>
                  <Box sx={{ pr: 2 }}>
                    {toPayment &&
                      (
                        toPayment?.AmountCur - toPayment?.SettleAmountCur
                      ).toFixed(2) +
                        ' ' +
                        invoice?.CurrencyCode}
                  </Box>
                </Stack>
              )
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}

            {invoice?.InvoiceId ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('InvoiceTotalSum')}</Box>
                <Box sx={{ textAlign: 'right', pr: 2 }}>
                  {invoice?.InvoiceAmount} {invoice?.CurrencyCode}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {invoice?.InvoiceId ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('InvoiceTaxSum')}</Box>
                <Box sx={{ pr: 2 }}>
                  {invoice?.SumTax} {invoice?.CurrencyCode}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
            {invoice?.InvoiceId ? (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 1, borderBottom: 'solid 1px #ddd' }}
              >
                <Box>{t('InvoiceTotalSumWithoutTax')}</Box>

                <Box sx={{ pr: 2 }}>
                  {invoice?.SalesBalance} {invoice?.CurrencyCode}
                </Box>
              </Stack>
            ) : (
              <Skeleton
                variant="rounded"
                sx={{ width: '100%', my: 1 }}
                height={19}
              />
            )}
          </>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label={t('ProductsTabTitle')} value="1" />
                {/* <Tab label={t('OrdersTabTitle')} value="2" /> */}
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                maxHeight: isDesktop ? '70vh' : 'auto',
                px: 0,
                overflow: 'auto',
              }}
            >
              <Typography
                sx={{ mt: 2, fontWeight: 'bold', mb: 2 }}
                as="p"
                variant="p"
              >
                {t('ProductsTabTitle')}
              </Typography>
              {!GetSingleInvoiceLinesApi.loading
                ? lines?.map((line, index) => (
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      sx={{ py: 1 }}
                    >
                      <Stack direction="row" spacing={2} sx={{ width: '65%' }}>
                        <Box sx={{ width: '30px' }}>
                          <Typography
                            as="p"
                            variant="h5"
                            sx={{ fontWeight: 'bold', opacity: 0.2 }}
                          >
                            {index + 1}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Typography>
                            <span style={{ fontWeight: 'bold' }}>
                              {line?.ItemId}
                            </span>{' '}
                            {line?.Name}
                          </Typography>
                        </Box>
                      </Stack>

                      <Box sx={{ textAlign: 'right' }}>
                        <Typography as="p">
                          {line?.LineAmount} {invoice?.CurrencyCode}
                        </Typography>
                        <Typography as="p" variant="caption">
                          {line?.SalesPrice} {invoice?.CurrencyCode} x{' '}
                          {line?.Qty} {line?.SalesUnitSymbolTranslate}.
                        </Typography>
                      </Box>
                    </Stack>
                  ))
                : [1, 2, 3, 4, 5].map((i) => (
                    <Skeleton
                      key={i}
                      variant="rounded"
                      sx={{ width: '100%', mb: 1 }}
                      height={64}
                    />
                  ))}
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </MainContainer>
  );
};
